<template>
<div>
    <!--  翁锦霖 -->
    <myheader></myheader>
    <!-- 头部背景图-->
    <div class="img-header" >
        <img src="../../../src/assets/pxrz.jpg" />
    </div>

    <!-- 中间导航栏-->
    <div  class="navigation1" hidden> 
        <ul>
            <li v-for="(navigation, index) in navigations" :key="'navigation-' + index">
                <a :href="navigation.tagName">{{ navigation.name }}</a>
            </li>
        </ul> 
    </div> 
    <!-- 有图案的背景图-->
    <div class="body">
        <!-- 方案概述 盒子+内容-->
        <div class="content" hidden>
            <!-- 内容盒子-->
            <div id="fags" class="content-Box wow animate__fadeInUp">
                <!-- 方案概述的名字-->
                <div class="title1 wow animate__fadeInUp" >
                    {{ programmeOverview.name }}
                </div>
                <!--方案概述的内容 -->
                <div class="content-box1">
                    <div class="title">
                        {{ programmeOverview.content }}
                    </div>
                    <!--方案概述的图片 -->
                    <div class="picture">
                        <img :src="programmeOverview.url" alt="" />
                    </div>
                </div>
            </div>
            <!-- 培养模式的盒子-->
            <div id="peyms" class="content-Box wow animate__fadeInUp">
                <!-- 培养模式的名字-->
                <div class="title1 wow animate__fadeInUp">
                    {{ trainingMode.name }}
                </div>
                <!-- 培养模式的内容-->
                <div class="content-box2">
                    {{ trainingMode.content }}
                </div>
                <!-- 培养模式的图片1-->
                <div class="commonly">
                    <div class="commonly2">
                        <img :src="trainingMode.url" alt="" />
                    </div>
                    <!-- 培养模式的图片1-->
                    <div class="commonly1">
                        <img :src="trainingMode.url1" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 特色培养盒子-->
        <div id="tspy" class="content-Box1" >
            <!-- 特色培养内容-->
            <div class="content4">
                <!-- 特色培养内容名称-->
                <div class="title1 wow animate__fadeInUp" hidden>{{ cultivationCharacteristics.name }}</div>
                <!-- 存放真实图片和内容的 盒子-->
                <div class="characteristic-Box">
                    <div class="wow animate__fadeInLeft">
                        <!-- 存放两张真实图片的 第一个盒子-->
                        <div class="characteristic-Box">
                            <div>
                                <img :src="cultivationCharacteristics.url" alt="" style='width:400px;'/>
                                <div class="real-Work">{{ cultivationCharacteristics.title }}</div>
                            </div>
                            <div>
                                <img :src="cultivationCharacteristics.url1" alt="" style='width:400px;margin-left: 30px;'/>
                                <div class="real-Case">{{ cultivationCharacteristics.title1 }}</div>
                            </div>
                        </div>
                        <!-- 存放两张真实图片的 第二个盒子-->
                        <div class="characteristic-Box">
                            <div>
                                <img :src="cultivationCharacteristics.url2" alt="" style='width:400px'/>
                                <div class="real-Case">{{ cultivationCharacteristics.title2 }}</div>
                            </div>
                            <div>
                                <img :src="cultivationCharacteristics.url3" alt="" style='width:400px;margin-left: 30px;'/>
                                <div class="real-Work">{{ cultivationCharacteristics.title3 }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 真实案例教学的盒子-->
                    <div class="teaching-Case wow animate__fadeInRight">
                        <div class="teaching-Content">{{ realCaseTeaching.name }}</div>
                        <div>
                            <img class="teaching-Icon" :src="url" hidden/>
                            <div class="teaching-Title">{{ realCaseTeaching.content }}</div>
                        </div>
                        <div>
                            <img class="teaching-Icon1" :src="url" hidden/>
                            <div class="teaching-Title1">{{ realCaseTeaching.content1 }}</div>
                        </div>
                        <div class="teaching-Icon2">
                            <img :src="realCaseTeaching.url1" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 工坊方向盒子-->
        <div id="gjfx" class="content-Box3" style="margin-bottom:30px">
            <!-- 工坊方向内容名称-->
            <div class="title1 wow animate__fadeIn">{{ workshopDirection.name }}</div>
            <!-- 工坊方向内容+图标-->
            <div class="icon wow animate__fadeInRight" hidden>
                <div><img :src="url" /></div>
                <div class="paragraph">
                    {{ workshopDirection.content }}
                </div>
            </div>
            <div class="icon1 wow animate__fadeInRight" hidden>
                <div><img :src="url" /></div>
                <div class="paragraph1">
                    {{ workshopDirection.content1 }}
                </div>
            </div>
            <!--for循环 工坊方向的几个盒子+方向详细内容 -->
            <div class="workshop-Direction">
                <div class="teaching-Case1 wow animate__fadeInUp" >
                    <div class="teaching-Icon3">
                        <img src="@/assets/chilun.png" />
                    </div>
                    <div class="teaching-Title2">云计算职业技能培训</div>
                    <div class="teaching-Title3">开展面向企业职工、社会人员的云计算领域职业技能培训工作</div>
                    <div class="teaching-Title3">•	266个注册机构</div>
                    <div class="teaching-Title3">•	18849个培训学员
                    </div>
                    <div class="teaching-Title3">•	1632小时线上培训课时
                    </div>
                    <div class="teaching-Title3">•	35445小时学习时长
                    </div>
                </div>
                <div class="teaching-Case1 wow animate__fadeInUp" >
                    <div class="teaching-Icon3">
                        <img src="@/assets/xiaochengxu.png" />
                    </div>
                    <div class="teaching-Title2">1+X培训</div>
                    <div class="teaching-Title3">协助院校开展1+X Web前端开发、1+X云计算平台运维与开发等职业技能等级证书培训工作，在新冠肺炎疫情防控工作期间免费开放技术平台在线培训帐号、在线训练环境、在线模拟考试场次供院校学生使用</div>
                    <div class="teaching-Title3">•	累计服务考证院校371所</div>
                    <div class="teaching-Title3">•	学生32271名
                    </div>
                    
                </div>
                <div class="teaching-Case1 wow animate__fadeInUp" >
                    <div class="teaching-Icon3">
                        <img src="@/assets/TV.png" />
                    </div>
                    <div class="teaching-Title2">就业服务</div>
                    <div class="teaching-Title3">依托人社部、工信部职业技能培训机构优势，与行业企业、院校构建了校企联盟就业资源池，为院校提供就业对接、就业培训服务，为企业输送切合岗位实际需求的人才</div>
                    <div class="teaching-Title3">•	300+企业联盟</div>
                    <div class="teaching-Title3">•	服务了1000+学生就业
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- 培养流程盒子 -->
        <div id="pylc"  hidden class="content-Box1 wow animate__fadeInUp" >
            <div class="content4">
                <div class="title1">{{ trainingProcess }}</div>
                <div class="characteristic-Box">
                    <div class="imgs"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="wow animate__bounce" >asdasd</div> -->
    <!-- 底部 -->
    <mybottom></mybottom>
</div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';

export default {
    components: {
        myheader,
        mybottom
    },
    data() {
        return {
            formSubmitCounsel: {
                phone: '',
                name: '',
                location: '',
                company: '',
                type: '中国特色企业新型学徒制',
                content: '',
            },
            trainingProcess: '培养流程',
            url: require('../../../src/assets/direction.png'),
            starts: '*',
            programmeOverview: {
                name: '方案概述',
                content: '基于现代学徒制，目任务驱动制，以培养具有工匠精神的高水平技术技能人才，推进“双师型”教师队伍建设，打造对外服务平台为目标，校企共建工匠工坊，提升职业教育育人质量，促进职业教育与产业协同发展。',
                url: require('../../../src/assets/fangan1.png'),
            },
            trainingMode: {
                name: '人才培养模型',
                content: '实施培养ICT高技能、高素质的人才与工匠之师，基于教育理论和公司在职教领域实践经验，构建工坊三维人才培养梩型：学习环境维度、职业能力维度和人才评测维度。',
                url: require('../../../src/assets/peiyang1.png'),
                url1: require('../../../src/assets/sanweirencai.png'),
            },
            cultivationCharacteristics: {
                name: '方案概述',
                url: require('../../../src/assets/pxrz1.jpg'),
                title: '',
                url1: require('../../../src/assets/pxrz2.jpg'),
                title1: '',
                url2: require('../../../src/assets/pxrz3.jpg'),
                title2: '',
                url3: require('../../../src/assets/pxrz4.jpg'),
                title3: '',
            },
            realCaseTeaching: {
                name: '',
                content: '公司开发的云计算平台、实训竞赛平台软件，已经与华为鲲鹏云完成兼容性认证，公司参与教育部、人社部相关培训，是人社部2020年发文推荐的54家线上学习平台之一，提供1+X Web前端开发、1+X云计算平台运维与开发等职业技能等级证书培训，免费开放技术平台在线培训帐号、在线训练环境、在线模拟考试场次供院校学生使用；协助高职院校建设“1+X”证书培训中心和认证中心。',
                url1: require('../../../src/assets/anlijiaoxue.png'),
            },
            workshopDirection: {
                name: '培训内容',
                content: '目前工匠工坊开设有7个方向，每个工坊方向培养的都是IT行业热门岗位需要的技能型，创新型人才。',
                content1: '单个工坊课程体系是由若干个由易及难的项目案例组成，学生学习完这些项目案例，相应开发技能获得逐步提升，一个培养周期结束，学生们就具备了对接工作岗位的相关技能。',
            },

            navigations: [{
                    id: 1,
                    name: '方案概述',
                    tagName: '#fags',
                },
                {
                    id: 3,
                    name: '培训内容',
                    tagName: '#gjfx',
                }
            ],
            teachings: [{
                    url: require('../../../src/assets/chilun.png'),
                    title: '云计算职业技能培训',
                    content: '真实项目案例活页式教材、工作手册、信息化教学资涌',
                },
                {
                    url: require('../../../src/assets/xiaochengxu.png'),
                    title: '1+x培训',
                    content: '院校教师企业工程师行业专家',
                },
                {
                    url: require('../../../src/assets/TV.png'),
                    title: '就业服务',
                    content: '项目式教学混合式教学行动导向教学',
                }
            ],
            programmeConsultation: {
                name: '方案咨询',
                content: '我们将根据您所填写的信息与您联系，进一步了解适合您的人才培养解决方案，确认使用需求。',
            },
        };
    },
    mounted() {
        var wow = new this.WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: true,
        });
        wow.init();
    },
    methods: {
        async submitCounsel() {
            let submitCounselUrl = `/home/submitCounsel`;
            let result = await this.axios.post(submitCounselUrl, this.formSubmitCounsel);
            this.$message.success('提交成功');
            console.log(result);
        },
        // gotoPage: function (id) {
        //   if (id == 1) {
        //     window.location.href = 'www.baidu.com';
        //   } else if (id == 2) {
        //     window.location.href = 'www.taobao.com';
        //   } else if (id == 3) {
        //     window.location.href = 'www.baidu.com';
        //   } else if (id == 4) {
        //     window.location.href = 'www.taobao.com';
        //   } else if (id == 5) {
        //     window.location.href = 'www.baidu.com';
        //   }
        // },
    },
};
</script>

<style lang="less" scoped>
.commonly {
    display: flex;
}

.commonly1 {
    margin-left: 75px;
    margin-top: 45px;
}

/**头部背景图 */
.img-header {
    margin: 0 auto;
    width: 100%;
    min-width: 1200px;
    max-width: 1920px;

    img {
        width: 100%;
    }

    /* height: 420px;
  background: url('../../../src/assets/imgHeader.png');
  background-position: 50% 50%;
  background-repeat: no-repeat; */
    /* background-origin: 50%; */
    /* background-size: 100%; */
}

ul {
    list-style-type: none;
    width: 100%;
    min-width: 1200px;
    background-color: #ffffff;
    display: flex;
    border: 1px solid #f0f0f0;
    justify-content: center;
}

li a {
    display: block;
    color: #747474;
    padding: 10px 20px;
    margin-left: 50px;
    // width: 100px;
    /* text-decoration: none; */
}
 
.navigation1 {
    margin-left: -100px; 
    position: sticky;
    top: 0; 
}

/* 鼠标移动到选项上修改背景颜色 */
li a:hover {
    background-color: #f2f2f2;
    color: #3569da;
}

.body {
    background: url('../../../src/assets/imgBody1.png') no-repeat;
    width: 100%;
    min-width: 1200px;
    justify-content: center;
}

.content {
    width: 1200px;
    height: 1080px;
    margin: 0 auto;
    justify-content: center;
    margin-top: 20px;
}

.content-Box {
    margin-top: 50px;
}

.content-box1 {
    width: 1200px;
    height: 280px;
    background-color: #ffffff;
    box-shadow: 0px 0px 3px 3px #e4dcdc;
    margin-top: 50px;
    display: flex;
}
.content-box5 {
    width: 1200px;
    height: 180px;
    background-color: #ffffff;
    box-shadow: 0px 0px 3px 3px #e4dcdc;
    margin-top: 50px;
    display: flex;
}

.title {
    width: 600px;
    height: 188px;
    font-size: 16px;
    color: black;
    /* box-sizing: border-box; */
    margin-left: 78px;
    margin-top: 82px;
    /* font-family: Adobe 黑体 Std; */
    letter-spacing: 0.5px;
    line-height: 2;
}

.picture {
    margin-left: 90px;
    margin-top: 40px;
}

.content-box2 {
    width: 830px;
    height: 131px;
    margin: auto;
    font-size: 16px;
    color: black;
    margin-top: 45px;
    justify-content: center;
    letter-spacing: 0.8px;
    line-height: 2;
}

.content-Box1 {
    width: 100%;
    min-width: 1200px;
    height: 600px;
    background-color: #f9f9f9;
}

.content2 {
    width: 1200px;
    /* width: 100%; */
    height: 1000px;
    margin: 0 auto;
    justify-content: center;
}

.title1 {
    margin-left: auto;
    margin-right: auto;
    color: black;
    font-weight: 600;
    font-size: 30px;
    padding-top: 65px;
    text-align: center;
}

.characteristic-Box {
    display: flex;
    margin-top: 30px;
    align-items: center;
    margin-right: 30px;
}

.teaching-Case {
    width: 476px;
    height: 476px;
    margin-top: 55px;
    background-color: white;
    box-shadow: 0px 0px 15px 1px rgb(226 222 222);
}

.teaching-Content {
    color: #2e71fe;
    font-size: 24px;
    margin-top: 60px;
    margin-left: 40px;
}

.teaching-Icon {
    margin-top: 50px;
    margin-left: 35px;
}

.teaching-Icon1 {
    margin-top: 40px;
    margin-left: 35px;
}

.teaching-Icon2 {
    margin-top: 55px;
    margin-left: 320px;
}

.teaching-Title1 {
    width: 365px;
    margin-left: 65px;
    margin-top: -30px;
    font-size: 16px;
    color: black;
    letter-spacing: 3px;
    /**字体间距 */
    line-height: 2;
    /**行间距 */
}

.teaching-Title {
    width: 365px;
    margin-left: 35px;
    margin-top: -30px;
    font-size: 16px;
    color: black;
    letter-spacing: 3px;
    /**字体间距 */
    line-height: 2;
    /**行间距 */
}

.real-Work {
    margin-left: 135px;
    margin-top: -20px;
    font-size: 16px;
    color: black;
    font-weight: 550;
}

.real-Case {
    margin-left: 180px;
    margin-top: -20px;
    font-size: 16px;
    color: black;
    font-weight: 550;
}

.icon {
    display: flex;
    padding: 30px;
}

.paragraph {
    margin-left: 20px;
    font-size: 16px;
    color: black;
}

.icon1 {
    display: flex;
    margin-left: 30px;
    /* margin-top: -20px; */
    margin-top: 10px;
}

.paragraph1 {
    margin-left: 20px;
    font-size: 16px;
    color: black;
    width: 850px;
    line-height: 2;
    margin-top: -5px;
}

.workshop-Direction {
    /* margin-top:-10px; */
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    /*盒子会自动掉下来*/
}

.teaching-Case1 {
    width: 270px;
    margin-left: 21px;
    margin-top: 30px;
    background-color: blaclk;
    box-shadow: 0px 0px 15px 1px rgb(226 222 222);
}

.teaching-Icon3 {
    text-align: center;
    margin-top: 30px;
    width: 100%;
    height: 50px;
}

.teaching-Title2 {
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
    color: black;
    font-size: 16px;
}

.teaching-Title3 {
    text-align: center;
    margin: 20px;
    box-sizing: border-box;
    // line-height: 2;
    font-size: 16px;
}

.imgs {
    margin: 0 auto;
    width: 1200px;
    height: 600px;
    background: url('../../../src/assets/teseliucheng.png');
    /* background-position: 50% 50%; */
    background-repeat: no-repeat;
    /* background-origin: 50%; */
    /* background-size: 100%; */
}

.img-foot {
    margin: 0 auto;
    width: 100%;
    height: 630px;
    background: url('../../../src/assets/jishufangan.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    /* background-origin: 50%; */
    /* background-size: 100%; */
}

.title2 {
    text-align: center;
    color: white;
    font-weight: 300;
    font-size: 24px;
    padding-top: 50px;
    letter-spacing: 3px;
}

.content3 {
    width: 630px;
    height: 30px;
    margin: auto;
    font-size: 13px;
    color: white;
    font-weight: 300;
    margin-top: 20px;
    justify-content: center;
    letter-spacing: 2px;
}

.content4 {
    width: 1200px;
    /* height:  ;  */
    margin: 0 auto;
    justify-content: center;
}

.content-box3 {
    width: 1200px;
    height: 410px;
    background-color: #ffffff;
    /* box-shadow: 0px 0px 3px 3px #e4dcdc; */
    margin-top: 20px;
    /* display: flex; */
}

.content-box4 {
    display: flex;
    margin-top: -30px;
}

.input-box {
    display: flex;
    margin-left: 180px;
    margin-top: 50px;
}

.textarea-box {
    width: 950px;
    height: 110px;
    display: flex;
    margin-left: 110px;
    margin-top: 30px;
}

.stars {
    color: red;
    margin-top: 10px;
}

.input-name {
    margin-top: 7px;
    margin-left: 5px;
    font-weight: 900;
}

.input1 {
    width: 300px;
    height: 35px;
    margin-left: 5px;
}

.input-name1 {
    margin-top: 7px;
    margin-left: 5px;
    font-weight: 900;
}

.input-box1 {
    display: flex;
    margin-left: 132px;
    margin-top: 50px;
}

.textarea1 {
    width: 835px;
    margin-left: 5px;
    height: 120px;
}

.button1 {
    width: 200px;
    height: 40px;
    margin-left: 500px;
    margin-top: 30px;
    color: white;
    background-color: #2e71fe;
    border-radius: 100px;
}

.t-anchor {
    position: fixed;
    right: 30px;
    top: 200px;
}
</style>
